import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from 'projects/app-shared/src/public-api';
import { Imagens } from '../etc/image.class'
import { EcommerceGenericoService } from 'projects/ecommerce-generico/src/public-api';

@Component({
  selector: 'mmax-tutoriales',
  templateUrl: './mmax-tutoriales.component.html',
  styleUrls: ['./mmax-tutoriales.component.scss']
})

export class MmaxTutorialesComponent implements OnInit {
  @Input('mode') modo: string;

  constructor(private router: ActivatedRoute, private modal: ModalService, private genServ:EcommerceGenericoService ) {
    this.icons = [
      { src: "../../assets/icons/truck-fast-outline.svg", label: 'Retiro en sucursal' },
      { src: "../../assets/icons/cart-arrow-down.svg", label: 'Como hacer una compra online' },
      { src: "../../assets/icons/credit-card-outline.svg", label: 'Medios de Pago' },
      // { src: "../../assets/icons/book-open-page-variant.svg", label: 'Catálogo' },
    ]
    this.modo = this.modo || 'normal';
  }

  ngOnInit(): void {
    this.router.data.subscribe(data => {
      this.modo = data['mode'] || this.modo || 'normal';
    });

  }

  goToTuto(label:string) {
    console.log("soy label", label);
    
    switch (label) {
      case 'Retiro en sucursal':
        this.modal.open('modal-retiro-sucursal');  
        break;
      case 'Como hacer una compra online':
        this.modal.open('modal-compra-online');
        break;
      case 'Medios de Pago':
        this.modal.open('modal-medio-pago');
        break;
      default:
        this.modal.open('modal-no-implemented');
        break;
    }
  }

  async cambiarColoresSVG(color: string, ruta: string) {

    var miImagenSVG = await fetch(ruta).then((res) => res.text());

    miImagenSVG = miImagenSVG.replace('#039072', color);

    var miImagen = document.getElementById('bubbleico') as HTMLImageElement;

    miImagen.innerHTML = miImagenSVG;

  }

  retirosucursal(){
    let data= this.genServ.getretiroSucursal()
    console.log("soy data",data);
    
    return data
  }

  
  
  icons: Imagens[];
}

