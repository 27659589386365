import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from 'projects/app-shared/src/public-api'
import { ArticulosEcommerce, ArticulosGeneral, EcommerceGenericoModule, EcommerceGenericoService, ImagesInterface, PromocionesEccomerce } from 'projects/ecommerce-generico/src/public-api';
import { MarianomGeneral } from '../../app-shared/marianom-general.service';


@Component({
  selector: 'app-mmaxhome-landpage',
  templateUrl: './mmaxhome-landpage.component.html',
  styleUrls: ['./mmaxhome-landpage.component.scss']
})
export class MmaxhomeLandpageComponent implements OnInit {
  @Input() ofertasDisplay: string;
  @Input() promosDisplay: string;
  @Input() marcasDisplay: string;

  sucursal: ArticulosEcommerce[];
  hayMarcas : boolean = false
  constructor(private genServ : EcommerceGenericoService, private modalserv: ModalService) {
    this.sucursal = [];
    this.ofertasDisplay = this.ofertasDisplay || 'none';
    this.promosDisplay = this.promosDisplay || 'none';
    this.marcasDisplay = this.marcasDisplay || 'none';
  }

  ngOnInit(): void { }

  closeModal(text) {
    this.modalserv.close(text);

  }

  get articulosEnOfertas() {
    const articulosEnOfertas : ArticulosGeneral[] = this.genServ.getOfertas();
    this.hayMarcas = this.genServ.getMarcasDestacadas().length !== 0;
    return articulosEnOfertas;
  }

  get articulosEnPromociones() {
    const articulosEnPromociones : ArticulosGeneral[] = this.genServ.getArticulosDePromociones();
    return articulosEnPromociones;
  }

}
