<carrusel-generico-view></carrusel-generico-view>
<section>
  <mmax-tutoriales></mmax-tutoriales>
</section>
<div *ngIf="articulosEnPromociones.length !== 0" >
  <mmax-landpage-frame [nombre]="'Promociones del mes'"></mmax-landpage-frame>
  <section >
    <div>
      <div class="articulosss">
        <lista-productos-view [articulosIn]="articulosEnPromociones" [modelo]="'promos-land'"></lista-productos-view>
      </div>
    </div>
  </section>
</div>

<div *ngIf="articulosEnOfertas.length !== 0" >
  <mmax-landpage-frame [nombre]="'Ofertas del mes'" ></mmax-landpage-frame>
  <section>
    <div>
      <div class="articulosss">
        <lista-productos-view [articulosIn]="articulosEnOfertas" [modelo]="'ofertas-land'"></lista-productos-view>
      </div>
    </div>
  </section>
</div>

<div *ngIf="hayMarcas" >
  <mmax-landpage-frame [nombre]="'Marcas destacadas'" ></mmax-landpage-frame>
  <section>
    <app-marcas-destmmax [mode]="'pc-land'"></app-marcas-destmmax>
  </section>
</div>


<mmax-helpfootbar></mmax-helpfootbar>
<foo-mariano-max></foo-mariano-max>
